import React from 'react';
import styled from "styled-components"

const FullWidthSectionWrapper = styled.section`
    max-width: 1180px; 
    width: 100%; 
    margin: 0 auto; 
    padding-left: ${props => props.spacing ? props.spacing : props.theme.spacings.xSmall}; 
    padding-right: ${props => props.spacing ? props.spacing : props.theme.spacings.xSmall};
    padding-bottom: ${props => props.spacing ? props.spacing : props.theme.spacings.small};

    >*:last-child {
        padding-bottom: 0 !important;
    }

    @media ${props => props.theme.breakpoints.desktop} {
        padding-left: ${props => props.spacing ? props.spacing : props.theme.spacings.small}; 
        padding-right: ${props => props.spacing ? props.spacing : props.theme.spacings.small};
        padding-bottom: ${props => props.spacing ? props.spacing : props.theme.spacings.medium};    
    }
`
const CenteredSectionWrapper = styled.section`
    max-width: 900px;
    width: 100%;
    margin: 0 auto;
    padding-left: ${props => props.spacing ? props.spacing : props.theme.spacings.xSmall};
    padding-right: ${props => props.spacing ? props.spacing : props.theme.spacings.xSmall};
    padding-bottom: ${props => props.spacing ? props.spacing : props.theme.spacings.small};

    >*:last-child {
        padding-bottom: 0 !important;
    }

    @media ${props => props.theme.breakpoints.desktop} {
        padding-left: ${props => props.spacing ? props.spacing : props.theme.spacings.small};
        padding-right: ${props => props.spacing ? props.spacing : props.theme.spacings.small};
        padding-bottom: ${props => props.spacing ? props.spacing : props.theme.spacings.medium};
    }
`

export const FullWidthSection = ({children}) => {
    return (
        <FullWidthSectionWrapper>
            {children}
        </FullWidthSectionWrapper>
    )
}

export const CenteredSection = ({children}) => {
    return (
        <CenteredSectionWrapper>
            {children}
        </CenteredSectionWrapper>
    )
}