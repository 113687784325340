import React from "react"
import {H3, H4, P, Li} from "./Typography"
import styled from "styled-components"

const BodyLink = styled.span`
    background-color: ${props => props.color ? props.color : props.theme.colors.grey1};
    color: inherit;
    border-radius: 5px;
    padding: 0 2px;
    box-decoration-break: clone;

    :hover  {
        background-color: ${props => props.color ? props.color : props.theme.colors.secondary};
        color: ${props => props.color ? props.color : props.theme.colors.background};
    }
`

const BlockUl = styled.ul`
  margin-left: ${props => props.theme.spacings.xSmall};
  
  @media ${props => props.theme.breakpoints.desktop} {
    margin-left: 0;
  }
`

export const block = {
    types: {
        block: props => {
            const style = props.node.style || "normal";

            if (style === "h3") {
                return <H3>{props.children}</H3>;
            } else if (style === "h4") {
                return <H4>{props.children}</H4>;
            } else {
                return <P context="block">{props.children}</P>
            }
        }
    },
    list: (props) =>
        props.type === 'bullet' ? (
            <BlockUl>{props.children}</BlockUl>
        ) : (
            <ol>{props.children}</ol>
        ),
    listItem: (props) => <Li context="block">{props.children}</Li>,
    marks: {
        link: props =>
            props.mark.blank === false ? (
                <BodyLink><a href={props.mark.href}>{props.children}</a></BodyLink> 
                ) : (
                <BodyLink><a href={props.mark.href} target='_blank' rel='noopener noreferrer'>{props.children}</a></BodyLink>
            )
    }
}

export const bigtext = {
    types: {
        block: props => {
            const style = props.node.style || "normal";

            if (style === "h3") {
                return <H3>{props.children}</H3>;
            } else if (style === "h4") {
                return <H4>{props.children}</H4>;
            } else {
                return <P context="bigtext">{props.children}</P>
            }
        }
    },
    list: (props) =>
        props.type === 'bullet' ? (
            <BlockUl>{props.children}</BlockUl>
        ) : (
            <ol>{props.children}</ol>
        ),
    listItem: (props) => <Li context="bigtext">{props.children}</Li>,
    marks: {
        link: props =>
            props.mark.blank === false ? (
                <BodyLink><a href={props.mark.href}>{props.children}</a></BodyLink> 
                ) : (
                <BodyLink><a href={props.mark.href} target='_blank' rel='noopener noreferrer'>{props.children}</a></BodyLink>
            )
    }
}

export const smalltext = {
    types: {
        block: props => {
            const style = props.node.style || "normal";

            if (style === "h3") {
                return <H3>{props.children}</H3>;
            } else if (style === "h4") {
                return <H4>{props.children}</H4>;
            } else {
                return <P context="smalltext">{props.children}</P>
            }
        }
    },
    list: (props) =>
        props.type === 'bullet' ? (
            <BlockUl>{props.children}</BlockUl>
        ) : (
            <ol>{props.children}</ol>
        ),
    listItem: (props) => <Li context="smalltext">{props.children}</Li>,
    marks: {
        link: props =>
            props.mark.blank === false ? (
                <BodyLink><a href={props.mark.href}>{props.children}</a></BodyLink> 
                ) : (
                <BodyLink><a href={props.mark.href} target='_blank' rel='noopener noreferrer'>{props.children}</a></BodyLink>
            )
    }
}