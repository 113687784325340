import styled from 'styled-components';

export const P = styled.p`
    ${props => {
        switch(props.context) {
            case "block":
                return "font-size: 18px; line-height: 25px; font-weight: 400; padding-bottom: 16px;"
            case "nonblock":
                return "font-size: 18px; line-height: 25px; font-weight: 400;"
            case "bigtext":
                return "font-size: 18px; line-height: 25px; font-weight: 400; padding-bottom: 16px;"
            case "smalltext":
                return "font-size: 16px; line-height: 22px; font-weight: 400;"
            case "smallinfo":  
                return "font-size: 14px; line-height: 20px; font-weight: 400;"
            default:
                return "font-size: 18px; line-height: 25px; font-weight: 400;"
        }
    }}
    color: ${props => props.color ? props.color : props.theme.colors.secondary};

    @media ${props => props.theme.breakpoints.desktop} {
        ${props => {
            switch(props.context) {
                case "block":
                    return "font-size: 24px; line-height: 34px; font-weight: 400; padding-bottom: 32px;"
                case "nonblock":
                    return "font-size: 24px; line-height: 34px; font-weight: 400;"
                case "bigtext":
                    return "font-size: 36px; line-height: 50px; font-weight: 400; padding-bottom: 32px;"
                case "smalltext":
                    return "font-size: 16px; line-height: 22px; font-weight: 400;"
                case "smallinfo":  
                    return "font-size: 14px; line-height: 20px; font-weight: 400;"
                default:
                    return "font-size: 24px; line-height: 34px; font-weight: 400;"
            }
        }}
    }
`

export const Li = styled.li`
    ${props => {
        switch(props.context) {
            case "block":
                return "font-size: 18px; line-height: 25px; font-weight: 400; padding-bottom: 6px;"
            case "bigtext":
                return "font-size: 18px; line-height: 25px; font-weight: 400; padding-bottom: 6px;"
            case "smalltext":
                return "font-size: 16px; line-height: 22px; font-weight: 400; padding-bottom: 4px;"
            case "smallinfo":  
                return "font-size: 14px; line-height: 20px; font-weight: 400; padding-bottom: 4px;"
            default:
                return "font-size: 18px; line-height: 25px; font-weight: 400; padding-bottom: 6px;"
        }
    }}
    color: ${props => props.color ? props.color : props.theme.colors.secondary};

    @media ${props => props.theme.breakpoints.desktop} {
        ${props => {
            switch(props.context) {
                case "block":
                    return "font-size: 24px; line-height: 34px; font-weight: 400; padding-bottom: 8px;"
                case "bigtext":
                    return "font-size: 36px; line-height: 50px; font-weight: 400; padding-bottom: 10px;"
                case "smalltext":
                    return "font-size: 16px; line-height: 22px; font-weight: 400; padding-bottom: 4px;"
                case "smallinfo":  
                    return "font-size: 14px; line-height: 20px; font-weight: 400; padding-bottom: 4px;"
                default:
                    return "font-size: 24px; line-height: 34px; font-weight: 400; padding-bottom: 8px;"
            }
        }}
    }
`

export const Caption = styled.figcaption`
    font-size: 14px;
    font-style: italic;
    line-height: 20px; 
    font-weight: 400; 
    padding:0;
    color: ${props => props.color ? props.color : props.theme.colors.secondary};
`

export const Button = styled.button`
    ${props => {
        switch(props.context) {
            case "block":
                return "font-size: 18px; line-height: 25px; font-weight: 400;"
            case "bigtext":
                return "font-size: 36px; line-height: 50px; font-weight: 400;"
            case "smalltext":
                return "font-size: 16px; line-height: 22px; font-weight: 400;"
            case "smallinfo":  
                return "font-size: 14px; line-height: 20px; font-weight: 400;"
            default:
                return "font-size: 18px; line-height: 25px; font-weight: 400;"
        }
    }}
    cursor: pointer;
    text-align: left;

    > a {
        padding: 2px 5px;
        border-radius: 5px;
        box-decoration-break: clone;
        background-color: ${props => props.color ? props.color : props.theme.colors.grey1};
        color: ${props => props.color ? props.color : props.theme.colors.foreground};

        :hover  {
            background-color: ${props => props.color ? props.color : props.theme.colors.flash};
        }
    }
`

export const H1 = styled.h1`
    margin: ${props => props.margin ? props.margin : 0};
    font-size: 28px;
    line-height: 1.2;
    color: ${props => props.color ? props.color : props.theme.colors.foreground};
    font-weight: 600;
    padding-bottom: ${props => props.theme.spacings.small};

    @media ${props => props.theme.breakpoints.desktop} {
        margin: ${props => props.margin ? props.margin : 0};
        font-size: 48px;
        line-height: 1.2;
        color: ${props => props.color ? props.color : props.theme.colors.foreground};
        font-weight: 600;
        padding-bottom: ${props => props.theme.spacings.medium};
    }
`

export const H2 = styled.h2`
    margin: ${props => props.margin ? props.margin : 0};
    font-size: 24px;
    line-height: 1.2;
    color: ${props => props.color ? props.color : props.theme.colors.foreground};
    font-weight: 600;
    padding-bottom: ${props => props.theme.spacings.xSmall};

    @media ${props => props.theme.breakpoints.desktop} {
        margin: ${props => props.margin ? props.margin : 0};
        font-size: 36px;
        line-height: 1.2;
        color: ${props => props.color ? props.color : props.theme.colors.foreground};
        font-weight: 600;
        padding-bottom: ${props => props.theme.spacings.small};
    }
`
export const H3 = styled.h3`
    margin: ${props => props.margin ? props.margin : 0};
    font-size: 20px;
    line-height: 1.2;
    color: ${props => props.color ? props.color : props.theme.colors.foreground};
    font-weight: 500;
    padding-bottom: ${props => props.theme.spacings.xSmall};

    @media ${props => props.theme.breakpoints.desktop} {
        margin: ${props => props.margin ? props.margin : 0};
        font-size: 24px;
        line-height: 1.2;
        color: ${props => props.color ? props.color : props.theme.colors.foreground};
        font-weight: 500;
        padding-bottom: ${props => props.theme.spacings.small};
    }
`

export const H4 = styled.h4`
    margin: ${props => props.margin ? props.margin : 0};
    font-size: 18px;
    line-height: 1.2;
    color: ${props => props.color ? props.color : props.theme.colors.foreground};
    font-weight: 500;
    padding-bottom: ${props => props.theme.spacings.xSmall};

    @media ${props => props.theme.breakpoints.desktop} {
        margin: ${props => props.margin ? props.margin : 0};
        font-size: 22px;
        line-height: 1.2;
        color: ${props => props.color ? props.color : props.theme.colors.foreground};
        font-weight: 500;
        padding-bottom: ${props => props.theme.spacings.xSmall};
    }
`

export const H5 = styled.h5`
    margin: ${props => props.margin ? props.margin : 0};
    font-size: 16px;
    line-height: 1.2;
    color: ${props => props.color ? props.color : props.theme.colors.foreground};
    font-weight: 400;
    padding-bottom: ${props => props.theme.spacings.xSmall};

    @media ${props => props.theme.breakpoints.desktop} {
        margin: ${props => props.margin ? props.margin : 0};
        font-size: 16px;
        line-height: 1.2;
        color: ${props => props.color ? props.color : props.theme.colors.foreground};
        font-weight: 400;
        padding-bottom: ${props => props.theme.spacings.xSmall};
    }
`

export const H6 = styled.h6`
    margin: ${props => props.margin ? props.margin : 0};
    font-size: 16px;
    line-height: 1.2;
    color: ${props => props.color ? props.color : props.theme.colors.foreground};
    font-weight: 400;
    padding-bottom: ${props => props.theme.spacings.xSmall};

    @media ${props => props.theme.breakpoints.desktop} {
        margin: ${props => props.margin ? props.margin : 0};
        font-size: 16px;
        line-height: 1.2;
        color: ${props => props.color ? props.color : props.theme.colors.foreground};
        font-weight: 400;
        padding-bottom: ${props => props.theme.spacings.xSmall};
    }
`