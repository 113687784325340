import React from "react"
import {Link} from "gatsby"
import styled from 'styled-components'
import * as Menu from "../components/menu"
import Footer from '../components/footer'
import {Button} from "../components/Typography"
import NotifBar from "../components/notification"

const MainGrid = styled.main`
  display: grid;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  grid-template-columns: 1fr;
  grid-auto-rows: auto 1fr auto;
  column-gap: 0;
  min-height: 100vh;
`

const Article = styled.article`
  padding-top: 60px;
  padding-bottom: 0;
  overflow: hidden;

  @media ${props => props.theme.breakpoints.desktop} {
    padding-top: 100px;
  }
`
const HomeButton = styled(Button)`
  position: fixed;
  z-index: 12;
  top: auto;
  bottom: ${props => props.spacing ? props.spacing : props.theme.spacings.xSmall};
  margin: auto;
  left: 50%;
  transform: translateX(-50%);

  @media ${props => props.theme.breakpoints.desktop} {
    top: ${props => props.spacing ? props.spacing : props.theme.spacings.xSmall};
    left: 10vw;
    transform: translateX(0);
    bottom: auto;
  }
`

export const Layout = ({ children, homebutton }) => {

  return (
    <>
      {homebutton ? (
        <HomeButton><Link to="/">&#127968;</Link></HomeButton>
      ) : (
        <></>
      )}
      <Menu.DesktopMenu/>
      <Menu.MobileMenu/>
      <NotifBar></NotifBar>
      <MainGrid>
        <Article>
          {children}
        </Article>
      </MainGrid>
      <Footer/>
    </>
  )
}

export const ModalLayout = ({ children }) => {

  return (
    <>
      <MainGrid>
        <Article>
          {children}
        </Article>
      </MainGrid>
    </>
  )
}
