import React from "react"
import styled from "styled-components"
import {GatsbyImage, getImage} from 'gatsby-plugin-image'
import { P, Button} from "../components/Typography"
import { useStaticQuery, graphql} from "gatsby"
import PortableText from "@sanity/block-content-to-react"
import * as Serializer from "../components/Serializer"

const Spacer = styled.div`
  width: 100%;
  height: ${props => props.theme.spacings.xSmall};

  @media ${props => props.theme.breakpoints.desktop} {
    height: ${props => props.theme.spacings.small};
  }
`
const FooterWrapper = styled.footer`
    position: relative;
    margin: auto;
    width: 94vw;
    // border-radius: ${props => props.theme.spacings.medium};
    // margin-bottom: ${props => props.theme.spacings.small};
    // width: 100%;
    border-radius: ${props => props.theme.spacings.medium};
    border: 1px solid ${props => props.theme.colors.flash};
    background-color: ${props => props.theme.colors.background};
    z-index: 3;
`
const FooterGrid = styled.div`
    padding-top: ${props => props.theme.spacings.small};
    padding-bottom: ${props => props.theme.spacings.small};
    width: 92%;
    margin: auto;
    display: grid;
    grid-template-columns: 1;
    grid-auto-rows: auto;
    column-gap: 0;

    @media ${props => props.theme.breakpoints.desktop} {
      grid-template-columns: repeat(3,minmax(0,1fr));
      column-gap: calc(6 / 1680 * 100vw);
    }
`

const DegreeName = styled(P)`
    grid-column: 1 / span 1;
    grid-row: 1 / span 1;
    padding-bottom: ${props => props.spacing ? props.spacing : props.theme.spacings.xxSmall};
    > span {
        background-color: ${props => props.color ? props.color : props.theme.colors.secondary};
        padding: 2px 5px;
        border-radius: ${props => props.spacing ? props.spacing : props.theme.spacings.small};
        box-decoration-break: clone;
        color: ${props => props.color ? props.color : props.theme.colors.background};
        margin-right: ${props => props.spacing ? props.spacing : props.theme.spacings.xxxSmall};
    }
`
const Direction = styled(P)`
    grid-column: 1 / span 1;
    grid-row: 2 / span 1;
    padding-bottom: ${props => props.spacing ? props.spacing : props.theme.spacings.small};

    @media ${props => props.theme.breakpoints.desktop} {
      padding-bottom: ${props => props.spacing ? props.spacing : props.theme.spacings.medium};
    }
`
const FooterPerson = styled.span`
    background-color: ${props => props.color ? props.color : props.theme.colors.flash};
    padding: 2px 5px;
    border-radius: 5px;
    box-decoration-break: clone;
    color: ${props => props.color ? props.color : props.theme.colors.foreground};
    margin-right: ${props => props.spacing ? props.spacing : props.theme.spacings.xxxSmall};
`
const EmailAddress = styled(P)`
    grid-column: 1 / span 1;
    grid-row: 5 / span 1;
    padding-bottom: ${props => props.spacing ? props.spacing : props.theme.spacings.xxxSmall};
    > span {
        background-color: transparent;
        padding: 2px 5px;
        border-radius: 5px;
        box-decoration-break: clone;
        border: 1px solid ${props => props.theme.colors.grey1};
        color: ${props => props.color ? props.color : props.theme.colors.secondary};
    }
`
const Phone = styled(P)`
    grid-column: 1 / span 1;
    grid-row: 6 / span 1;
    > span {
        background-color: transparent;
        padding: 2px 5px;
        border-radius: 5px;
        box-decoration-break: clone;
        border: 1px solid ${props => props.theme.colors.grey1};
        color: ${props => props.color ? props.color : props.theme.colors.secondary};
    }

    padding-bottom: ${props => props.spacing ? props.spacing : props.theme.spacings.medium};

    @media ${props => props.theme.breakpoints.desktop} {
      padding-bottom: 0;
    }
`
const ContactIntro = styled(P)`
    grid-column: 1 / span 1;
    grid-row: 3 / span 1;
    padding-bottom: ${props => props.spacing ? props.spacing : props.theme.spacings.xSmall};
`
const Contact = styled(P)`
    grid-column: 1 / span 1;
    grid-row: 4 / span 1;
    padding-bottom: ${props => props.spacing ? props.spacing : props.theme.spacings.xSmall};
`

const Address = styled.div`
  grid-column: 1 / span 1;
  grid-row: 8 / span 1;
  padding-bottom: ${props => props.spacing ? props.spacing : props.theme.spacings.small};

    @media ${props => props.theme.breakpoints.desktop} {
      grid-column: 2 / span 1;
      grid-row: 1 / span 2;
      padding-bottom: 0;
    }
`

const Socials = styled(P)`
  grid-column: 1 / span 1;
  grid-row: 9 / span 1;
  padding-bottom: ${props => props.spacing ? props.spacing : props.theme.spacings.medium};

  @media ${props => props.theme.breakpoints.desktop} {
    grid-column: 2 / span 1;
    grid-row: 3 / span 3;
    padding-bottom: 0;
  }
`

const Logos = styled.div`
    grid-column: 1 / span 1;
    grid-row: 10 / span 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    column-gap: ${props => props.theme.spacings.xSmall};

    @media ${props => props.theme.breakpoints.desktop} {
      grid-column: 3 / span 1;
      grid-row: 1 / span 3;
    }
`

const ImageDiv = styled.div`
//   padding: ${props => props.spacing ? props.spacing : props.theme.spacings.xSmall};
  width: 100px;

  @media ${props => props.theme.breakpoints.desktop} {
    // padding: ${props => props.spacing ? props.spacing : props.theme.spacings.small};
    width: 150px;
  }
`

const ExtLinkButton = styled(Button)`
  margin-right: ${props => props.spacing ? props.spacing : props.theme.spacings.xxxSmall};
`

const Footer = ({children}) => {
    const data = useStaticQuery(graphql`
    query FooterQuery {
        allSanityGeneralInfo {
            nodes {
              contact {
                contact_name
                email
                phone
                role
              }
              degreename
              direction
              _rawLocation
              extLinks_array {
                extAccount
                extUrl
              }
            }
        }
        allSanityLogos {
            nodes {
              logosArray {
                company
                website
                logo {
                  asset {
                    gatsbyImageData (
                        width: 200
                        placeholder: BLURRED
                        formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
              }
            }
        }
    }
  `);

  const contactInfo = data.allSanityGeneralInfo.nodes[0].contact
  const degreeName = data.allSanityGeneralInfo.nodes[0].degreename
  const direction = data.allSanityGeneralInfo.nodes[0].direction
  const location = data.allSanityGeneralInfo.nodes[0]._rawLocation
  const logos = data.allSanityLogos.nodes[0].logosArray
  const extLinks = data.allSanityGeneralInfo.nodes[0].extLinks_array
  // console.log(extLinks)
    return (
      <>
      <FooterWrapper>
          <FooterGrid>
            <DegreeName context="smalltext"><span>Master 2 {degreeName}</span></DegreeName>
            <Direction context="smalltext">sous la direction de <FooterPerson>{direction}</FooterPerson></Direction>
            <ContactIntro context="smalltext">Pour toute information, veuillez contacter&nbsp;:</ContactIntro>
            <Contact context="smalltext"><FooterPerson>{contactInfo.contact_name}</FooterPerson><br></br>{contactInfo.role}</Contact>
            <EmailAddress context="smalltext"><span>{contactInfo.email}</span></EmailAddress>
            <Phone context="smalltext"><span>{contactInfo.phone}</span></Phone>
            <Address>
                <PortableText
                blocks={location}
                serializers = {Serializer.smalltext}
                />
            </Address>
            <Socials context="smalltext">
              Retrouvez l'actualité du m2ecn sur {extLinks.map((extLink, k) => {return (<ExtLinkButton context="smallinfo" key={k}><a href={extLink.extUrl} target="_blank" rel='noopener noreferrer'>{extLink.extAccount}</a></ExtLinkButton>)})}
            </Socials>
            <Logos>
              {logos.map((logo, i) => {
                const image = getImage(logo.logo.asset)
                return (
                <ImageDiv key={i}><a href={logo.website} target="_blank" rel='noopener noreferrer'><GatsbyImage image={image} alt={logo.company}/></a></ImageDiv>
                )
              }   
              )}
            </Logos>
          </FooterGrid>
      </FooterWrapper>
      <Spacer></Spacer>
      </>
    ) 
  }
  
  export default Footer