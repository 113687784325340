/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function Seo({ description, lang, meta, title, author, url, image, keywords }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            image
            url
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title
  const metaAuthor = author || site.siteMetadata.author
  const metaUrl = url || site.siteMetadata.url
  const metaImage = image || site.siteMetadata.image
  const metaKeywords = keywords || ["master","master culture","culture","numérique","économie","master pro","m2ecn","sorbonne","paris 1","master 2","industries culturelles","médias"]

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title ? `${defaultTitle} | ${title}` : `${defaultTitle} | Univ. Paris 1 Panthéon-Sorbonne`}
      // titleTemplate={title ? `%s | ${defaultTitle}` : `${defaultTitle}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title ? `${title}` : `${defaultTitle}`,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: metaImage,
        },
        {
          property: `og:url`,
          content: metaUrl,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:title`,
          content: title ? `${title}` : `${defaultTitle}`,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image`,
          content: metaImage,
        },
      ].concat(
        metaKeywords && metaKeywords.length > 0 ? {
          name: `keywords`,
          content: metaKeywords.join(`, `),
        } : []
      )
      }
    />
  )
}

Seo.defaultProps = {
  lang: `fr`,
  meta: [],
  description: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
}

export default Seo
