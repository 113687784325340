import React from "react"
import styled from 'styled-components'
import {useStaticQuery, graphql} from "gatsby"
import Marquee from "react-fast-marquee"
import PortableText from "@sanity/block-content-to-react"
import * as Serializer from "../components/Serializer"

const NotifContainer = styled.div`
    z-index: 2;
    position: fixed;
    bottom: auto;
    top: ${props => props.spacing ? props.spacing : props.theme.spacings.xSmall};
    max-width: 90%;
    margin: auto;
    left: 50%;
    transform: translateX(-50%);
    background-color: ${props => props.theme.colors.background};
    height: ${props => props.theme.spacings.small};
    border-radius: ${props => props.theme.spacings.xSmall};
    border: 1px solid ${props => props.theme.colors.flash};
    text-align: center;
    white-space: nowrap;

    @media ${props => props.theme.breakpoints.desktop} {
        max-width: 50%;
        bottom: ${props => props.spacing ? props.spacing : props.theme.spacings.small};
        top: auto;
        height: ${props => props.theme.spacings.medium};
        border-radius: ${props => props.theme.spacings.small};
    }
`

const NotifMarquee = styled(Marquee)`
    top: 50%;
    transform: translateY(-50%);
`

const NotifTextContainer = styled.div`
    padding-left: ${props => props.theme.spacings.xSmall};
    padding-right: ${props => props.theme.spacings.small};
    padding-bottom: 0;
    display: block;
    overflow:hidden;
    height: auto;
    position: relative;
    white-space: nowrap;
    color: ${props => props.theme.colors.foreground};

    > * {
        padding-bottom: 0;
    }

    @media ${props => props.theme.breakpoints.desktop} {
        padding-left: ${props => props.theme.spacings.small};
    }
`

const NotifBar = ({children}) => {
    const data = useStaticQuery(graphql`
        query NotifQuery {
            allSanityNotification {
                nodes {
                    _rawNotifText
                    activate
                }
            }
        }
    `)
    const NotifText = data.allSanityNotification.nodes[0]._rawNotifText
    const NotifActivate = data.allSanityNotification.nodes[0].activate
    
    return (
        NotifActivate ? (
            <NotifContainer>
                <NotifMarquee direction="left" gradient={false} pauseOnHover={true} speed="30">
                    <NotifTextContainer>
                        <PortableText
                            blocks={NotifText}
                            serializers = {Serializer.block}
                        />
                    </NotifTextContainer>
                </NotifMarquee>
            </NotifContainer>
        ) : (
            <></>
        )
    )
}

export default NotifBar