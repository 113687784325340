import React, {useState, useEffect, useRef} from "react"
import styled from 'styled-components'
import { AnchorLink } from "gatsby-plugin-anchor-links";

export default function useComponentVisible(initialIsVisible) {
  const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible);
  const ref = useRef(null);

  const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
          setIsComponentVisible(false);
      }
  };

  useEffect(() => {
      document.addEventListener('click', handleClickOutside, true);
      return () => {
          document.removeEventListener('click', handleClickOutside, true);
      };
  }, []);

  return { ref, isComponentVisible, setIsComponentVisible };
}

const DesktopMenuWrapper = styled.nav`
  display: none;
  
  @media ${props => props.theme.breakpoints.desktop} {
    display: block;
    z-index: 11;
    position: fixed;
    width: 90vw;
    top: ${props => props.spacing ? props.spacing : props.theme.spacings.xSmall}; 
    margin: auto;
    left: 50%;
    transform: translateX(-50%);
  }
`

const DesktopMenuList = styled.ul`
  position: relative;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  list-style: none;
  margin: auto;
  padding: 0;
`

const DesktopMenuItem = styled.li`
  margin-bottom: ${props => props.spacing ? props.spacing : props.theme.spacings.xSmall};
  background-color: ${props => props.color ? props.color : props.theme.colors.grey1};
  padding: 2px 5px;
  border-radius: 5px;
  color: ${props => props.color ? props.color : props.theme.colors.foreground};
  cursor: pointer;

  :hover {
      background-color: ${props => props.color ? props.color : props.theme.colors.flash};
  }
`

const TopMenuWrapper = styled.div`
  display: block;
  z-index: 11;
  position: fixed;
  right: ${props => props.spacing ? props.spacing : props.theme.spacings.xSmall}; 
  bottom: ${props => props.spacing ? props.spacing : props.theme.spacings.xSmall}; 
  margin: auto;
  border-radius: 10px;

  @media ${props => props.theme.breakpoints.desktop} {
    display: none;
  }
`

const MobileMenuWrapper = styled.details`
  // display: block;
  // z-index: 11;
  // position: fixed;
  // right: ${props => props.spacing ? props.spacing : props.theme.spacings.xSmall}; 
  // bottom: ${props => props.spacing ? props.spacing : props.theme.spacings.xSmall}; 
  // margin: auto;
  // border-radius: 10px;
  
  // @media ${props => props.theme.breakpoints.desktop} {
  //   display: none;
  // }
`

const MobileMenuList = styled.ul`
  // position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
`

const MobileMenuItem = styled.li`
  margin-bottom: ${props => props.spacing ? props.spacing : props.theme.spacings.xSmall};
  background-color: ${props => props.color ? props.color : props.theme.colors.grey1};
  padding: 2px 5px;
  border-radius: 5px;
  color: ${props => props.color ? props.color : props.theme.colors.foreground};
  cursor: pointer;

  :hover {
      background-color: ${props => props.color ? props.color : props.theme.colors.flash};
  }
`

const Summary = styled.summary`
  list-style: none;
  background-color: ${props => props.color ? props.color : props.theme.colors.grey1};
  padding: 2px 5px;
  border-radius: 5px;
  color: ${props => props.color ? props.color : props.theme.colors.foreground};
  cursor: pointer;

  :hover {
      background-color: ${props => props.color ? props.color : props.theme.colors.flash};
  }
`

const Nav = styled.nav`
  position: absolute;
  bottom: ${props => props.spacing ? props.spacing : props.theme.spacings.small};
  width: 100%;
  border-radius: 10px;
`

export const DesktopMenu = () => {
    return (
        <DesktopMenuWrapper>
            <DesktopMenuList>
                <DesktopMenuItem><AnchorLink to="/#maquette" title="Maquette" className="stripped" stripHash>Maquette</AnchorLink></DesktopMenuItem>
                <DesktopMenuItem><AnchorLink to="/#rencontres" title="Rencontres" className="stripped" stripHash>Rencontres</AnchorLink></DesktopMenuItem>
                <DesktopMenuItem><AnchorLink to="/#alternance" title="Alternance" className="stripped" stripHash>Alternance</AnchorLink></DesktopMenuItem>
                <DesktopMenuItem><AnchorLink to="/#admissions" title="Admissions" className="stripped" stripHash>Admissions</AnchorLink></DesktopMenuItem>
            </DesktopMenuList>
        </DesktopMenuWrapper>
    )
}

export const MobileMenu = () => {
    const [open, toggleOpen] = useState(false);

    // useEffect(() => {
    //   setMount(false)
    // }, []);
    const {
      ref,
      isComponentVisible,
      setIsComponentVisible
    } = useComponentVisible(false);

    return (
      <TopMenuWrapper ref={ref} >

        <MobileMenuWrapper open={isComponentVisible}>
            <Summary>Menu</Summary>
            <Nav>
            <MobileMenuList onClick={() => open ? (setIsComponentVisible(!open)) : (setIsComponentVisible(true))}>
                <MobileMenuItem><AnchorLink to="/#maquette" title="Maquette" stripHash>Maquette</AnchorLink></MobileMenuItem>
                <MobileMenuItem><AnchorLink to="/#rencontres" title="Rencontres" stripHash>Rencontres</AnchorLink></MobileMenuItem>
                <MobileMenuItem><AnchorLink to="/#alternance" title="Alternance" stripHash>Alternance</AnchorLink></MobileMenuItem>
                <MobileMenuItem><AnchorLink to="/#admissions" title="Admissions" stripHash>Admissions</AnchorLink></MobileMenuItem>
            </MobileMenuList>
            </Nav>
        </MobileMenuWrapper>
      </TopMenuWrapper>
    )
}